import React, { useState } from "react";
import { Document, Page, pdfjs, Outline } from "react-pdf";
import { Button, InputNumber, Spin } from "antd";
import "../components/FirebasePage/index.css";
import { LOADER_MESSAGE } from "../constants/config";
import { ArrowLeftOutlined, ArrowRightAltOutlined, ArrowRightOutlined } from "@material-ui/icons"

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default ({ url }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function onItemClick({ pageNumber: itemPageNumber }) {
    setPageNumber(itemPageNumber);
  }

  const decrementPageCount = () => {
    setPageNumber(prev => prev <= 1 ? 1 : prev - 1);
  }

  const incrementPageCount = () => {
    setPageNumber(prev => prev >= numPages ? numPages : prev + 1);
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p style={{ fontSize: 12 }}>
          Showing Page {pageNumber || (numPages ? 1 : "--")} of{" "}
          {numPages || "--"}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Button style={{ position: "absolute", top: "50%", left: "0%", borderColor: "lightgray", zIndex: 10000 }} icon={<ArrowLeftOutlined />} onClick={decrementPageCount}></Button>
        <Button style={{ position: "absolute", top: "50%", right: "0%", zIndex: 10000 }} icon={<ArrowRightOutlined />} onClick={incrementPageCount}></Button>
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={
            <div className="LoaderContainer">
              <Spin size="large" tip={LOADER_MESSAGE} />
            </div>
          }
          className={"PDFViewerContainer"}
        >
          <Outline onItemClick={onItemClick} />
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
    </div>
  );
};
