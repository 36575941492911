const backendHosts = {
  dev: "http://localhost:3002",
  prod: "http://www.tilicho.in:3010",
};

const environment = "prod";

const firebaseConfigs = {
  dev: {
    apiKey: "AIzaSyBCqiyfailXSM4Zeeg0mplRULF4tjq46as",
    authDomain: "supadha-dev.firebaseapp.com",
    databaseURL: "https://supadha-dev.firebaseio.com",
    projectId: "supadha-dev",
    storageBucket: "supadha-dev.appspot.com",
    messagingSenderId: "1019856256943",
    appId: "1:1019856256943:web:dc5dcf379dcb3d0d68f95a",
    measurementId: "G-674RGWTPZZ",
  },

  prod: {
    apiKey: "AIzaSyCsKKC2OZXManf8DQBGBoy1iqWL0a3oZl0",
    authDomain: "supadha-prod.firebaseapp.com",
    databaseURL: "https://supadha-prod-default-rtdb.firebaseio.com",
    projectId: "supadha-prod",
    storageBucket: "supadha-prod.appspot.com",
    messagingSenderId: "922966587091",
    appId: "1:922966587091:web:879bfcc008562d668f8626",
  },
};

export const HOST = backendHosts[environment];
export const FIREBASE_CONFIG = firebaseConfigs[environment];

export const LOADER_MESSAGE = "శ్రీ గురుభ్యో నమ:";
