import {ReadOutlined} from "@ant-design/icons";
import {Button, Card, Modal} from "antd";
import {map, values} from "lodash";
import React, {useEffect, useRef, useState} from "react";
import PDFViewer from "../../utils/PDFViewer";
import useWindowDimensions from "../../utils/useWindowDimensions";
import FirebasePage from "../FirebasePage";
import "./index.css";

const titleStyle = {
	paddingLeft: "10px",
	textAlign: "center",
};

export default () => {
	const [showingBookViewer, setShowingBookViewer] = useState(false);
	const currentPublication = useRef({});

	const {width} = useWindowDimensions();

	const [gridStyle, setGridStyle] = useState({
		width: "30%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	});

	useEffect(() => {
		setGridStyle({
			...gridStyle,
			width: width < 700 ? "100%" : width < 1050 ? "50%" : "30%",
		});
	}, [width]);

	const showBookViewer = (e, publication) => {
		e.preventDefault();
		currentPublication.current = publication;
		setShowingBookViewer(true);
	};

	const hideBookViewer = () => {
		setShowingBookViewer(false);
		currentPublication.current = {};
	};

	return (
		<FirebasePage
			paths={["publications"]}
			renderResult={([publicationsDict]) => {
				const publications = values(publicationsDict);

				return (
					<Card
						title={<h1>ప్రచురణలు</h1>}
						type="inner"
						className="top-container"
					>
						<div className="master-container">
							{map(publications, (publication) => (
								<Card.Grid
									style={gridStyle}
									key={publication.id}
									className="publication-item"
								>
									<img
										style={{width: 195, height: 320}}
										alt={publication.title}
										src={publication.imageUrl}
									/>
									<div className="publication-item-content">
										<h3 style={titleStyle} className="publication-item-title">
											{publication.title}
										</h3>
										<h3 style={titleStyle}>
											<Button
												type="default"
												shape="round"
												size="large"
												icon={<ReadOutlined />}
												onClick={(e) => showBookViewer(e, publication)}
											>
												View
											</Button>
										</h3>
										{/* <h3 style={titleStyle}>
											<Button
												type="primary"
												shape="round"
												size="large"
												icon={<DownloadOutlined />}
												onClick={() =>
													window.open(publication.pdfUrl, "_blank")
												}
											>
												Download
											</Button>
										</h3> */}
									</div>
								</Card.Grid>
							))}
							{showingBookViewer && (
								<Modal
									title={currentPublication.current.title}
									visible={showingBookViewer}
									onCancel={hideBookViewer}
									centered
									className="PDFModal"
									footer={[]}
								>
									<PDFViewer url={currentPublication.current.pdfUrl} />
								</Modal>
							)}
						</div>
					</Card>
				);
			}}
		/>
	);
};
